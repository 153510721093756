<template>
  <v-container
    id="profile"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-card-text"
          color="yellow darken-1"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $auth.user().name }}
            </div>
          </template>

          <v-form @submit.prevent="saveUser()">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.name"
                    :label="$tc('name', 1)"
                    :rules="[rules.required, rules.countmin5, rules.countmax40]"
                    :error-messages="errors.name"
                    maxlength="40"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.email"
                    :label="$tc('email', 1)"
                    :rules="[rules.required, rules.email, rules.countmax60]"
                    :error-messages="errors.email"
                    maxlength="60"
                    autocomplete="username"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="password"
                    :label="$tc('password', 1)"
                    :rules="[rules.countmin8, rules.countmax20]"
                    :error-messages="errors.password"
                    maxlength="20"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    autocomplete="new-password"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="passwordConfirm"
                    :label="$tc('password', 1) + ' (' + $tc('confirmation', 1) +')'"
                    :rules="[rules.passwordconfirm]"
                    :error-messages="errors.passwordConfirm"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="new-password"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="black"
                    type="submit"
                    class="mr-0"
                  >
                    {{ $tc('save', 1) }}
                  </v-btn>
                  <v-overlay
                    :value="loader"
                    absolute
                  >
                    <v-progress-circular indeterminate />
                  </v-overlay>
                  <v-snackbar
                    v-model="snackbar.active"
                    :timeout="snackbar.timeout"
                    color="secondary"
                    rounded="pill"
                    absolute
                    centered
                  >
                    {{ snackbar.text }}

                    <template #action="{ attrs }">
                      <v-btn
                        color="success"
                        text
                        fab
                        small
                        rounded
                        v-bind="attrs"
                        @click="snackbar.active = false; snackbar.text = ''"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Profile',

    data: function () {
      return {
        store: this.$store,
        user: {},
        password: '',
        passwordConfirm: '',
        showPassword: false,
        rules: {
          required: value => !!value || this.$tc('required', 1),
          countmin5: value => value.length >= 5 || this.$tc('lengthmin', 1) + 5,
          countmin8: value => value.length === 0 || value.length >= 8 || this.$tc('lengthmin', 1) + 8,
          countmax20: value => value.length <= 20 || this.$tc('lengthmax', 1) + 20,
          countmax40: value => value.length <= 40 || this.$tc('lengthmax', 1) + 40,
          countmax60: value => value.length <= 60 || this.$tc('lengthmax', 1) + 60,
          countmax255: value => value.length <= 255 || this.$tc('lengthmax', 1) + 255,
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$tc('emailinvalid', 1)
          },
          passwordconfirm: value => {
            return value === this.password || this.$tc('passwordconfirmerror', 1)
          },
        },
        errors: {
          name: null,
          email: null,
          password: null,
        },
        loader: false,
        snackbar: {
          active: false,
          text: '',
          color: 'error',
          timeout: 2000,
        },
      }
    },
    created () {
      this.user.name = this.$auth.user().name
      this.user.email = this.$auth.user().email
    },
    methods: {
      saveUser () {
        var self = this
        self.loader = true
        if (self.password.length >= 8 && self.password.length <= 20) self.user.password = self.password
        self.$http.put('/api/user', self.user).then(function (response) {
          if (self.store.state.result === 422) {
            if (self.store.state.message.name) self.errors.name = [self.$t('invalids.' + self.store.state.message.name[0].split('.').join(''))]
            if (self.store.state.message.email) self.errors.email = [self.$t('invalids.' + self.store.state.message.email[0].split('.').join(''))]
            if (self.store.state.message.password) self.errors.password = [self.$t('invalids.' + self.store.state.message.password[0].split('.').join(''))]
          } else {
            self.passwordConfirm = self.password = ''
            self.$auth.fetch()
            self.snackbar.color = 'primary'
            self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('saved', 1)
            self.loader = false
            self.snackbar.active = true
          }
        }, function (response) {
          self.snackbar.color = 'error'
          self.snackbar.text = self.$tc('user', 1) + ' ' + self.$tc('savefailed', 1)
          self.loader = false
          self.snackbar.active = true
        })
      },
    },
  }
</script>
